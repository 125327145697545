<template>
  <b-card class="display-results mb-4">
    <ais-instant-search
      :search-function="searchFunction"
      :routing="routing"
      :search-client="searchClient"
      :index-name="indexName"
    >
      <b-row>
        <b-col>
          <div class="display-results__search-wrapper">
            <ais-search-box class="display-results__search mb-4">
              <template v-slot="{ refine }">
                <b-input
                  v-model="searchQuery"
                  placeholder="Search here..."
                  type="search"
                  ref="searchField"
                  :autofocus="Boolean(searchQuery)"
                  @input="refine(searchQuery)"
                />
              </template>
            </ais-search-box>
          </div>

          <b-form-group v-if="refinedFacetList" label="Exact Filter" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="searchableFacet"
              :options="refinedFacetList"
              :aria-describedby="ariaDescribedby"
            />
          </b-form-group>

          <ais-configure v-if="refinedFacetList" :restrictSearchableAttributes="searchableAttributes" />

          <ais-hits>
            <div slot-scope="{ items }">
              <slot :items="items" />
            </div>
          </ais-hits>

          <ais-pagination class="mt-4" />
        </b-col>

        <b-col cols="2" />
      </b-row>
    </ais-instant-search>
  </b-card>
</template>

<script>
import { configureSearchClient } from '../pages/page-helpers'
import 'instantsearch.css/themes/algolia-min.css'
import debounce from 'lodash/debounce'

export default {
  name: 'DisplayResults',
  props: {
    indexName: { type: String },
    query: { type: String },
    facetList: { type: Array },
    searchableFacetDefault: {type: String, required: false, default: 'voucher_code'}
  },
  data() {
    const vueRouter = this.$router

    return {
      searchQuery: this.$route.query.query,
      searchClient: configureSearchClient(
        process.env.VUE_APP_ALGOLIA_ID,
        process.env.VUE_APP_ALGOLIA_SEARCH_KEY,
        process.env.VUE_APP_ALGOLIA_LOCAL_READ_API
      ),
      routing: {
        router: {
          read() {
            return vueRouter.currentRoute.query
          },
          write(routeState) {
            vueRouter.push({
              query: routeState,
            })
          },
          createURL(routeState) {
            return vueRouter.resolve({
              query: routeState,
            }).href
          },
          onUpdate(cb) {
            this._onPopState = event => {
              const routeState = event.state

              // at initial load, the state is read from the URL without
              // update. Therefore the state object is not there. In this
              // case we fallback and read the URL.
              if (!routeState) {
                cb(this.read())
              } else {
                cb(routeState)
              }
            }
            window.addEventListener('popstate', this._onPopState)
          },
          dispose() {
            window.removeEventListener('popstate', this._onPopState)
            this.write()
          },
        },
        stateMapping: {
          stateToRoute: uiState => {
            return {
              query: uiState[this.indexName].query,
              page: uiState[this.indexName].page,
            }
          },
          routeToState: routeState => {
            return {
              [this.indexName]: {
                query: routeState.query,
                page: routeState.page,
              },
            }
          },
        },
      },
      searchableFacet: this.searchableFacetDefault,
    }
  },

  computed: {
    refinedFacetList() {
      if (!this.facetList) return

      return this.facetList.concat([{ text: 'Everything', value: '__everything' }])
    },
    searchableAttributes() {
      if (this.searchableFacet === '' || this.searchableFacet === '__everything' || !this.searchQuery) {
        return []
      }

      return [this.searchableFacet]
    },
  },

  methods: {
    searchFunction(helper) {
      this.search(helper, this.searchQuery)
    },
    search: debounce((helper, query) => {
      const page = helper.getPage() // Retrieve the current page

      helper
        .setQuery(query) // this call resets the page
        .setPage(page) // we re-apply the previous page
        .search()
    }, 300),
  },
}
</script>
<style lang="scss" scoped>
.display-results {
  &__search-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__search {
    min-width: 75%;
  }

  &__search-btn {
    margin: 0 0.2rem;
    min-width: 15%;
  }
}
</style>
